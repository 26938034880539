import React, { useEffect, useState } from 'react';
import { array, bool, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../util/types';
import { ListingCard, PaginationLinks } from '../../../components';

import css from './SearchResultsPanel.module.css';
/* import getCountryCodes from '../../../translations/countryCodes'; */
import { groupedByCoordinates, reducedToArray } from '../SearchMap/SearchMap.helpers';
import Cards from './Cards';
import heartImg from '../../../assets/heart_white_filled.png';
import heartImgFill from '../../../assets/heart_filled.webp';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import AuthModal from '../../../components/AuthModal/AuthModal';
import Notification from '../../../components/notification-popup/notification';
import { updateProfile } from '../../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useIntl } from 'react-intl';

const SearchResultsPanelComponent = props => {
  const [notification, setNotification] = useState(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [favorite, Setfavorite] = useState(false);
  const [favAfterAuth, setFavAfterAuth] = useState({});
  const intl = useIntl();
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    isMapVariant,
    currentUser,
    isAuthenticated,
    onUpdateProfile,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  /*   useEffect(()=>{
    if(search?.address != null && search?.address != undefined) {
    const countryCodes =  getCountryCodes().find(country => country.name == search.address)?.code ?? '';
    countryCodes != '' ?  setSortedListings(sortListings(listings,countryCodes)) :setSortedListings(listings);
    }else{
      setSortedListings(listings);
    }
  },[listings,search]) */

  const listingArraysInLocations = reducedToArray(groupedByCoordinates(listings));
  /*   function sortListings(listings, code) {
    return listings.sort((a, b) => {
        const aCountry = a.attributes.publicData.country;
        const bCountry = b.attributes.publicData.country;

        if (aCountry === code && bCountry !== code) {
            return -1; // a comes first
        } else if (aCountry !== code && bCountry === code) {
            return 1;  // b comes first
        } else {
            return 0;  // no change in order
        }
    });
   } */
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const favoriteFunction = async listing => {
    let listingId = listing.id;
    if (!currentUser.attributes.profile.publicData.favoritList) {
      currentUser.attributes.profile.publicData.favoritList = [];
    }
    const favoritList = currentUser.attributes.profile.publicData.favoritList;
    const index = favoritList.findIndex(item => item.listId === listingId.uuid);
    if (index !== -1) {
      favoritList.splice(index, 1);
      await onUpdateProfile({ publicData: { favoritList } });
      Setfavorite(false);
      let notif = intl.formatMessage({ id: 'favoritePage.removed' });
      setNotification(notif);
    } else {
      favoritList.push({ listId: listingId.uuid });
      await onUpdateProfile({ publicData: { favoritList } });
      Setfavorite(true);
      let notif = intl.formatMessage({ id: 'favoritePage.added' });
      setNotification(notif);
    }
  };

  const isFavoriteListing = listing => {
    if (!isAuthenticated || !currentUser) {
      return false;
    }
    const checkFavorite = list => {
      let listingId = list.id;
      if (!currentUser?.attributes?.profile?.publicData?.favoritList) {
        currentUser.attributes.profile.publicData.favoritList = [];
      }
      const favoritList = currentUser.attributes.profile.publicData.favoritList;
      const index = favoritList.findIndex(item => item.listId == listingId.uuid);
      return index !== -1;
    };

    if (Array.isArray(listing)) {
      for (let item of listing) {
        if (checkFavorite(item)) {
          return true;
        }
      }
      return false;
    } else {
      return checkFavorite(listing);
    }
  };
  const actionAfterAuth = () => {
    setTimeout(() => {
      favoriteFunction(favAfterAuth);
    }, 1000);
  };

  useEffect(() => {
    if (pagination) {
      if (typeof window !== 'undefined' && window?.document) {
        document.body.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [pagination]);

  return (
    <div className={classes}>
      <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
        {notification && (
          <Notification message={notification} onClose={() => setNotification(null)} />
        )}
        {authModalOpen ? (
          <AuthModal
            isOpen={authModalOpen}
            setOpen={setAuthModalOpen}
            redirectToCheckout={actionAfterAuth}
          />
        ) : null}
        {listingArraysInLocations.map((l, index) => (
          <div key={index} className={css.cardWrapper}>
            {l.length === 1 ? (
              <div className={css.listingCardOuterWrapper}>
                <ListingCard
                  className={css.listingCard}
                  key={l[0].id.uuid}
                  listing={l[0]}
                  renderSizes={cardRenderSizes}
                  setActiveListing={setActiveListing}
                />
              </div>
            ) : (
              <Cards array={l} setActiveListing={setActiveListing} length={l?.length} />
            )}
            <div className={css.favButton}>
              <button
                onClick={e => {
                  e.preventDefault();
                  if (isAuthenticated) {
                    favoriteFunction(l[0]);
                  } else {
                    setFavAfterAuth(l[0]);
                    setAuthModalOpen(true);
                  }
                }}
                className={css.favButtonChild}
              >
                <img src={isFavoriteListing(l) ? heartImgFill : heartImg} className={css.favImg} />
              </button>
            </div>
          </div>
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanelComponent.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanelComponent.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.auth;
  return { currentUser, isAuthenticated };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});
const SearchResultsPanel = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchResultsPanelComponent);

export default SearchResultsPanel;
